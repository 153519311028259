import { Injectable } from '@angular/core';

import { Events, NavController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { AlertService } from 'src/app/services/alert.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    userId;
    notificationStatus;

    constructor(
        private authService: AuthService,
        public localNotifications: LocalNotifications,
        public events: Events,
        private navCtrl: NavController,
        private alertService: AlertService
    ) {
        this.userId = localStorage.getItem("userId");
        
        this.notificationStatus = localStorage.getItem("notifications");
    }

    async checkNotification() {
        this.userId = localStorage.getItem("userId");
        this.notificationStatus = localStorage.getItem("notifications");

        if (this.userId && this.notificationStatus){
            
            // Get unsent notifications
            this.authService.getList('notification-grab', this.userId).subscribe((data) => {

                if (data['success']){

                    this.events.publish('newNoti', data['notifications'].length);
                    
                    data['notifications'].forEach(aNoti => {
                        
                        // Create Device notifications
                        this.localNotifications.schedule({
                            id: aNoti.id,
                            title: 'Broadside',
                            text: aNoti.message,
                            foreground: true
                        });

                        // Create Browser notifications
                        if (Notification.permission !== "granted") {
                            Notification.requestPermission();
                        } else {
                            new Notification('Broadside', {
                                body: aNoti.message,
                            });
                        }
                    });
                } else {

                    if (data['type'] == 'relog'){
                        this.alertService.presentToast('Session invalid - please login again');
                        this.authService.logoutUser();
                        this.navCtrl.navigateBack('/landing');
                    }
                    
                }
            });
        }
    }


    async addNotification(theMessage, userId = 'true', theType = 'default', theCompanyID = 'false') {

        // If userId == 0 then notification is sent to ALL users

        // Default is user ID of current user
        if (userId == 'true') {
            userId = this.userId;
        }

        this.authService.addNotification(theMessage, userId, theType, theCompanyID).subscribe((data) => {
			if (data['success']){
				return true;
			} else {
                return false;
            }
        });
    }
}

import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { IonicModule } from '@ionic/angular';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { HttpRequestInterceptor } from './services/HttpRequestInterceptor';
import { SideService } from './components/side.service';
import { myTransitionAnimation } from './services/transitionAnimation';


@NgModule({
    declarations: [
        AppComponent,
        SideMenuComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios',
            navAnimation: myTransitionAnimation
        }),
        AppRoutingModule,
        HttpClientModule
    ],
	exports: [SideMenuComponent],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        InAppBrowser,
        SideService,
        LocalNotifications
    ],
    bootstrap: [AppComponent, SideMenuComponent]
})
export class AppModule {}

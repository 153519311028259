import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterOutlet } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(
        private navCtrl: NavController
    ) {}

    stringToBoolean(string){
        switch(string.toLowerCase().trim()){
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
        }
    }


    canActivate(route: ActivatedRouteSnapshot): boolean {
        var expectedRole;
        
        if (route.data.expectedRole){
            expectedRole = route.data.expectedRole;
        } else {
            expectedRole = '';
        }
		
        if (expectedRole == 'admin'){
            var isAdmin = parseInt(localStorage.getItem("isAdmin"));

            return this.stringToBoolean(isAdmin);
        }

        if (expectedRole == 'manager'){
            var isManager = parseInt(localStorage.getItem("isManager"));

            return this.stringToBoolean(isManager);
        }


        if (localStorage.getItem("authToken")) {
            return true;
        } else {
            this.navCtrl.navigateRoot('/landing');
            return false;
        }
    }


}

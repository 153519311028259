import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class EnvService {
    API_URL = 'https://broadside-data.nw.r.appspot.com/';
   // API_URL = 'http://192.168.1.5:3420/';
    //API_URL = 'http://192.168.1.64:3420/';

	BOOKING_TIMES = [
		{ name: '12:00am', id: 0 },
		{ name: '1:00am', id: 1 },
		{ name: '2:00am', id: 2 },
		{ name: '3:00am', id: 3 },
		{ name: '4:00am', id: 4 },
		{ name: '5:00am', id: 5 },
		{ name: '6:00am', id: 6 },
		{ name: '7:00am', id: 7 },
		{ name: '8:00am', id: 8 },
		{ name: '9:00am', id: 9 },
		{ name: '10:00am', id: 10 },
		{ name: '11:00am', id: 11 },
		{ name: '12:00pm', id: 12 },
		{ name: '1:00pm', id: 13 },
		{ name: '2:00pm', id: 14 },
		{ name: '3:00pm', id: 15 },
		{ name: '4:00pm', id: 16 },
		{ name: '5:00pm', id: 17 },
		{ name: '6:00pm', id: 18 },
		{ name: '7:00pm', id: 19 },
		{ name: '8:00pm', id: 20 },
		{ name: '9:00pm', id: 21 },
		{ name: '10:00pm', id: 22 },
		{ name: '11:00pm', id: 23 }
	];

	BOOKING_DURATIONS = [
		{ name: '45 min', id: 0 },
		{ name: '1 hours', id: 1 },
		{ name: '2 hours', id: 2 },
		{ name: '3 hours', id: 3 },
		{ name: '4 hours', id: 4 },
		{ name: '5 hours', id: 5 },
		{ name: '6 hours', id: 6 },
		{ name: '7 hours', id: 7 },
	];
    
    constructor() { }
}

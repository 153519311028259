import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

// , canActivate: [AuthGuard], data: {expectedRole: 'admin'} ]},
const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    , canActivate: [AuthGuard]},

    { path: 'landing', loadChildren: './pages/landing/landing.module#LandingPageModule'},
    { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule' , canActivate: [AuthGuard]},
    
    { path: 'maintenance-dash', loadChildren: './pages/maintenance/maintenance.module#MaintenancePageModule' , canActivate: [AuthGuard]},
    { path: 'social-dash', loadChildren: './pages/social/social.module#SocialPageModule' , canActivate: [AuthGuard]},
    { path: 'events-dash', loadChildren: './pages/social/events/events.module#EventsPageModule' , canActivate: [AuthGuard]},
    { path: 'gym-dash', loadChildren: './pages/social/gym/gym.module#GymPageModule' , canActivate: [AuthGuard]},
    { path: 'settings-dash', loadChildren: './pages/settings/settings.module#SettingsPageModule' , canActivate: [AuthGuard]},
  	{ path: 'spaces-dash', loadChildren: './pages/booking/booking.module#BookingPageModule', canActivate: [AuthGuard]},

    { path: 'news', loadChildren: './pages/news/news.module#NewsPageModule', canActivate: [AuthGuard]},
    { path: 'vouchers', loadChildren: './pages/vouchers/vouchers.module#VouchersPageModule' , canActivate: [AuthGuard]},

    { path: 'events', loadChildren: './pages/event/event.module#EventPageModule', canActivate: [AuthGuard]},

    { path: 'gym', loadChildren: './pages/gym/gym.module#GymPageModule' , canActivate: [AuthGuard]},

    { path: 'user', loadChildren: './pages/user/user.module#UserPageModule', canActivate: [AuthGuard]},
    { path: 'user/:cid', loadChildren: './pages/user/user.module#UserPageModule', canActivate: [AuthGuard]},

    { path: 'spaces', loadChildren: './pages/spaces/spaces.module#SpacesPageModule', canActivate: [AuthGuard]},
    { path: 'faq', loadChildren: './pages/faq/faq.module#FaqPageModule' , canActivate: [AuthGuard]},
    { path: 'credit', loadChildren: './pages/user/credit/credit.module#CreditPageModule', canActivate: [AuthGuard]},
    { path: 'review', loadChildren: './pages/review/review.module#ReviewPageModule', canActivate: [AuthGuard]},
    { path: 'notification', loadChildren: './pages/notifications/notifications.module#NotificationsPageModule', canActivate: [AuthGuard]},
    { path: 'forgot', loadChildren: './pages/landing/forgot/forgot.module#ForgotPageModule', canActivate: [AuthGuard]},
    { path: 'contact', loadChildren: './pages/contact/contact.module#ContactPageModule', canActivate: [AuthGuard]},
    { path: 'reports', loadChildren: './pages/reports/reports.module#ReportsPageModule' , canActivate: [AuthGuard]},
    
    { path: 'waiver', loadChildren: './pages/waiver/waiver.module#WaiverPageModule' },
    { path: 'waiver/manage', loadChildren: './pages/waiver/manage/manage.module#ManagePageModule' },  { path: 'view', loadChildren: './pages/waiver/manage/view/view.module#ViewPageModule' },

  
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {}

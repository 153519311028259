import { Animation, NavOptions } from '@ionic/core';


export function myTransitionAnimation(AnimationC: Animation, _: HTMLElement, opts: TransitionOptions): Promise<Animation> {

    const TRANSLATE_DIRECTION = 'translateX';
    const OFF_BOTTOM = '100%';
    const OFF_TOP = '-100%';
    const CENTER = '0px';
    const enteringEl = opts.enteringEl;
    const leavingEl = opts.leavingEl;
    const ionPageElement = getIonPageElement(enteringEl);
    const rootTransition = new AnimationC();
    const animDuration = 250;
    const animEasing = 'cubic-bezier(.42,0,1,1)';

    rootTransition
        .addElement(ionPageElement)
        .beforeRemoveClass('ion-page-invisible');

    const backDirection = (opts.direction === 'back');


    // animate the component itself
    if (backDirection) {


        rootTransition
            .duration(animDuration)
            .easing(animEasing);
    } else {
        
        const menuList = ionPageElement.querySelector('.menu-grid');
        const menuItem = new AnimationC();
        menuItem
            .addElement(menuList)
            .duration(animDuration)
            .fromTo('opacity', 0, 1);

            rootTransition.add(menuItem);

        rootTransition
            .duration(animDuration)
            .easing(animEasing)
            .fromTo(TRANSLATE_DIRECTION, OFF_BOTTOM, CENTER, true)
            .fromTo('opacity', 1, 1, true);
    }


    // setup leaving view
    if (leavingEl && backDirection) {



        rootTransition
            .duration(animDuration)
            .easing(animEasing);

        const leavingPage = new AnimationC();
        leavingPage
            .addElement(getIonPageElement(leavingEl))
            .fromTo(TRANSLATE_DIRECTION, CENTER, OFF_BOTTOM)
            .fromTo('opacity', 1, 1);

        const menuList = leavingEl.querySelector('.menu-grid');
        const menuItem = new AnimationC();
        menuItem
            .addElement(menuList)
            .duration(animDuration)
            .fromTo('opacity', 1, 0);

        leavingPage.add(menuItem);

        rootTransition.add(leavingPage);        
    }

    return Promise.resolve(rootTransition);

}


export interface TransitionOptions extends NavOptions {

    progressCallback?: ((ani: Animation | undefined) => void);
    window: Window;
    baseEl: any;
    enteringEl: HTMLElement;
    leavingEl: HTMLElement | undefined;

}


function getIonPageElement(element: HTMLElement) {
    if (element.classList.contains('ion-page')) {
        return element;
    }

    const ionPage = element.querySelector(':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs');

    if (ionPage) {
        return ionPage;
    }

    return element;
}
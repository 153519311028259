import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { Md5 } from 'ts-md5/dist/md5';



@Injectable({
    providedIn: 'root'
})

export class AuthService {

    constructor(
        private http: HttpClient,
        private env: EnvService,
    ) { }


    // Special Calls

    login(email, password) {
        password = Md5.hashStr(password);

        return this.http.post(this.env.API_URL + 'auth/login', { email: email, password: password })
        .pipe(
            tap(data => {
                return data;
            })
        );
    }

  
    logoutUser() {
		localStorage.removeItem("authToken");
		localStorage.removeItem("userId");
		localStorage.removeItem("isAdmin");
		localStorage.removeItem("isManager");
		localStorage.removeItem("isSpend");
		localStorage.removeItem("firstName");
		localStorage.removeItem("lastName");
		localStorage.removeItem("notifications");

		return true;
    }

    user() {
        return this.http.get(this.env.API_URL + 'auth/user')
        .pipe(
            tap(user => {
                return user;
            })
        )
    }


    
	bookingList(approvedOnly = 'all', userId = false) {
		var filters = {};

		if (approvedOnly == '1'){
			filters['approved'] = 1;
		}
		if (approvedOnly == '0'){
			filters['approved'] = 0;
		}

		if (userId == true){
			filters['user_id'] = localStorage.getItem("userId");
        }
        
  
        if (typeof userId != "boolean"){
            if (!isNaN(parseFloat(userId)) && isFinite(userId)){
                filters['user_id'] = userId;
            }
        }

 
		var data = {};
		data['filters'] = filters;
        return this.http.post(this.env.API_URL + 'booking/list', data)
        .pipe(
            tap(bookingList => {
                return bookingList;
            })
        )
    }

    forgotPassword(theEmail){
        var data = {'email' : theEmail};
        return this.http.post(this.env.API_URL + 'auth/forgot', data);
    }

    addNotification(theMessage, userId, theType, theCompanyID) {

        return this.http.post(this.env.API_URL + 'notification/add', {
            message: theMessage,
            user_id: userId,
            type: theType,
            company_id: theCompanyID,
            time: Math.round(Date.now() / 1000)
        });
    }









    // GENERALISED DB CALLS

    filterList(listName, filters) {
        return this.http.post(this.env.API_URL + listName + '/list', {filters:filters})
        .pipe(
            tap(returnList => {
                return returnList;
            })
        )
    }

    getList(typeName, theId = null) {

        if (theId == null) {
            theId = '';
        }
        
        return this.http.get(this.env.API_URL + typeName + '/list/' + theId)
        .pipe(
            tap(theList => {
                return theList;
            })
        )
    }

    getRow(typeName, theId){
        return this.http.get(this.env.API_URL + typeName + '/get/' + theId)
        .pipe(
            tap(theRow => {
                return theRow;
            })
        )
    }

    getValue(typeName, theId) {
        return this.http.get(this.env.API_URL + typeName + '/check/' + theId)
        .pipe(
            tap(theValue => {
                return theValue;
            })
        )
    }

    hitUrl(theUrl) {
        return this.http.get(this.env.API_URL + theUrl);
    }

    updateRow(theUrl, theData) {
        return this.http.post(this.env.API_URL + theUrl, theData);
    }
}

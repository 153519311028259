import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    userId;
    
    constructor(
        private platform: Platform,
		public notificationService: NotificationService
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {

            // fade out and remove the #splash
            const splash = document.getElementById('splash');
            const splashLogo = document.getElementById('splash-logo');

            //splash.style.opacity = '0';
            setTimeout(() => {
                splashLogo.classList.remove("animate__pulse");
                splashLogo.classList.remove("animate__infinite");
                splashLogo.classList.add("animate__bounceOut");
            }, 2500);

            setTimeout(() => {
                splash.remove();
            }, 2900);

            
            

            this.userId = localStorage.getItem("userId");
            
            if (this.userId){
                this.notificationService.checkNotification();
            }

            setInterval(() => { 
                this.notificationService.checkNotification();
            }, 60000);
        });
    }

}

import { Component, OnInit } from '@angular/core';
import { NavController, Platform, Events} from '@ionic/angular';
import { SideService } from '../side.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
	notiNum;
	userId;

	constructor(
		public navCtrl: NavController,
		public tabs: SideService,
		public events: Events
	) {
		events.subscribe('newNoti', (data) =>{
			this.notiNum = data;
		});
	}


	ngOnInit() {
	}


	navToPage(path){
		this.navCtrl.navigateForward(path);
	}

}

import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders
} from '@angular/common/http';

import { Observable } from 'rxjs';


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // If we have a token, send it with any request
        if (localStorage.getItem("authToken")) {
            var authToken = localStorage.getItem("authToken");

            req = req.clone({
                headers: new HttpHeaders({
                    'Authorization': authToken
                })
            });
        }

        return next.handle(req);
    }
}
